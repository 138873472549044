<template>
  <v-app>
    <section class="d-flex flex-column">
      <Header v-if="!$route.meta.authOnly" @open-login-modal="showLogin = true" @open-register-modal="showRegister = true"/>
      <div v-if="$route.name === 'home'">
        <v-container class="mb-15 pt-3 pt-sm-15 pb-10">
          <v-row>
            <v-col
                cols="12"
                md="6"
                class="pr-md-15 order-2 order-md-1"
            >
              <h1
                  class="text-center text-md-left logo-text"
                  :class="{
                 'mb-8': !companySettings.image,
                 'mb-2': companySettings.image
               }"
              >{{ companySettings.title }}</h1>
              <v-row>
                <v-col cols="12" class="text-center text-md-left">
                  <img
                      v-if="companySettings.image"
                      :src="companySettings.image"
                      :alt="companySettings.alt"
                      class="mb-6"
                      :width="130"
                  >
                </v-col>
              </v-row>
              <TopicList :topics="topics" class="mb-9"></TopicList>
              <v-btn
                  elevation="5"
                  color="primary"
                  large
                  block
                  class="text-uppercase"
                  @click="handleMainRegisterClick"
              >
                <span>{{ $t('texts.home.register_now_for_free') }}</span>
              </v-btn>
              <v-row class="mt-4">
                <v-col cols="4">
                  <v-img
                      src="assets/img/safety/data-store.svg"
                      :alt="$t('texts.home.security.data_store')"
                      class="elevation-5 rounded"
                  />
                </v-col>
                <v-col cols="4">
                  <v-img
                      src="assets/img/safety/dsgvo.svg"
                      :alt="$t('texts.home.security.dsgvo')"
                      class="elevation-5 rounded"
                  />
                </v-col>
                <v-col cols="4">
                  <v-img
                      src="assets/img/safety/ssl.svg"
                      :alt="$t('texts.home.security.ssl')"
                      class="elevation-5 rounded"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col
                cols="12"
                md="6"
                class="order-1 order-md-2"
            >
              <VideoPlayer
                  src="/assets/video/welcome.mp4"
                  class="pa-2 elevation-5 rounded primary"
                  cover="/assets/img/home/welcome-cover.png"
              />
              <SocialMediaIcons style="margin-top: 9px"></SocialMediaIcons>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="mb-10">
          <v-row class="d-flex flex-column align-center justify-center">
            <v-col cols="12" class="text-center">
              <v-icon
                  id="learn-more-icon"
                  size="38"
                  color="primary"
                  @click="scrollToFeatures"
              >mdi-chevron-down
              </v-icon>
            </v-col>
          </v-row>
        </v-container>
        <div class="bg-gray pa-10 mb-15">
          <v-container>
            <h2 class="mb-7 text-body-1 text-center text-uppercase">{{ $t('texts.home.in_cooperation_with') }}</h2>
            <vue-marquee-slider
                id="partner-logo-slider"
                class="pb-4 pt-4"
                :speed="2500 * partners.length"
                :width="130"
                :space="60"
            >
              <img
                  v-for="(image, index) in partners"
                  class="elevation-5 pl-3 pr-3 pt-2 pb-2 bg-main rounded"
                  :key="index"
                  :src="image.logo"
                  :alt="image.name"
              />
            </vue-marquee-slider>
          </v-container>
        </div>
        <Features :features="features" id="features" class="mb-15 pt-10"></Features>
        <div class="bg-gray pt-10 pb-3 mb-15">
          <SectionTitle :title="$t('texts.home.usage_points.title')" :subtitle="$t('texts.home.usage_points.subtitle')"></SectionTitle>
          <UsagePoints :points="usagePoints" class="mb-15"></UsagePoints>
        </div>
        <SectionTitle :title="$t('texts.home.customer_feedback.title')" :subtitle="$t('texts.home.customer_feedback.subtitle')"></SectionTitle>
        <GoogleReviews class="mb-14 mt-n4"></GoogleReviews>
        <div class="bg-gray pt-10 pb-3 mb-15">
          <SectionTitle :title="$t('texts.home.statistics.title')" :subtitle="$t('texts.home.statistics.subtitle')"></SectionTitle>
          <Statistics :statistics="statistics" class="mb-15"></Statistics>
        </div>
        <SectionTitle :title="$t('texts.home.pricing.title')" :subtitle="$t('texts.home.pricing.subtitle')"></SectionTitle>
        <Pricing class="mb-16" @open-register-modal="openPricingRegisterModal"></Pricing>
        <Footer
            :showLogin="showLogin"
            :showRegister="showRegister"
            :pricing-plan="pricingPlan"
            @close-login-modal="showLogin = false"
            @close-register-modal="handleCloseRegisterModal"
        />
      </div>
      <v-btn
          v-if="$store.getters.isLoggedIn && $route.name !== 'supportIndex'"
          color="secondary"
          fab
          fixed
          bottom
          right
          @click="$router.push({name: 'support'})"
      >
        <v-icon color="white">mdi-headset</v-icon>
      </v-btn>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </section>
  </v-app>
</template>

<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import Features from "@/widgets/Features.vue";
import VideoPlayer from "@/widgets/VideoPlayer.vue";
import SocialMediaIcons from "@/widgets/SocialMediaIcons.vue";
import {VueMarqueeSlider} from "vue-marquee-slider";
import Statistics from "@/widgets/Statistics.vue";
import SectionTitle from "@/widgets/SectionTitle.vue";
import UsagePoints from "@/widgets/UsagePoints.vue";
import GoogleReviews from "@/widgets/GoogleReviews.vue";
import Pricing from "@/widgets/Pricing.vue";
import TopicList from "@/widgets/TopicList.vue";

export default {
  components: {
    TopicList,
    Pricing,
    GoogleReviews,
    UsagePoints,
    SectionTitle,
    Statistics,
    VueMarqueeSlider,
    SocialMediaIcons,
    VideoPlayer,
    Features,
    Header,
    Footer,
  },
  data() {
    return {
      companySettings: {
        title: this.$t('texts.home.title.default'),
        image: '',
        alt: ''
      },
      topics: [
        this.$t('texts.home.reasons.reason1'),
        this.$t('texts.home.reasons.reason2'),
        this.$t('texts.home.reasons.reason3'),
      ],
      partners: [
        {name: "alleato assekuranzmakler GmbH", logo: "assets/img/home/alleato.png"},
        {name: "adesso SE", logo: "assets/img/home/adesso.png"},
        {name: "Continentale Versicherungsverbund", logo: "assets/img/home/continentale.png"},
        {name: "Kraftfahrer-Schutz e.V.", logo: "assets/img/home/ksauxilia.png"},
        {name: "Fortuna Düsseldorf 1895", logo: "assets/img/home/fortuna.png"},
        {name: "Füchse Duisburg", logo: "assets/img/home/fuechse.png"},
        {name: "IDEAL Versicherung AG", logo: "assets/img/home/ideal.png"},
        {name: "JURA DIREKT GmbH", logo: "assets/img/home/juradirekt.png"},
        {name: "Rainbow International Systemzentrale Deutschland GmbH", logo: "assets/img/home/rainbow.png"},
        {name: "Safe-iD.eu", logo: "assets/img/home/safeid.png"},
        {name: "Stadt Schleiden", logo: "assets/img/home/schleiden.png"},
        {name: "Techniker Krankenkasse", logo: "assets/img/home/tk.png"},
        {name: "DeinSportsfreund.de", logo: "assets/img/home/dsf.png"},
        {name: "Syncro24 – assekuradeur GmbH", logo: "assets/img/home/syncro24.png"},
        {name: "Wechselpilot GmbH", logo: "assets/img/home/wechselpilot.png"},
        {name: "Enwikuna UG (haftungsbeschränkt)", logo: "assets/img/home/enwikuna.png"},
        {name: "Allvest GmbH", logo: "assets/img/home/allvest.png"},
        {name: "Boheme Boutiqe Events UG (haftungsbeschränkt)", logo: "assets/img/home/life4style.png"},
        {name: "Sanitätshaus QUARG GmbH", logo: "assets/img/home/quarg.png"},
        {name: "Crusader Investments B.V.", logo: "assets/img/home/eventhotels.png"},
      ],
      features: [
        {
          target: 'https://www.alleato.eu',
          image: 'assets/img/home/insurance.svg',
          title: this.$t('texts.home.features.insurance.title'),
          description: this.$t('texts.home.features.insurance.description'),
        },
        {
          target: '/produkte/checkliste',
          image: 'assets/img/home/checklist.svg',
          title: this.$t('texts.home.features.checklist.title'),
          description: this.$t('texts.home.features.checklist.description'),
        },
        {
          target: '/produkte/persoenliche-botschaft',
          image: 'assets/img/home/personal-message.svg',
          title: this.$t('texts.home.features.personal_message.title'),
          description: this.$t('texts.home.features.personal_message.description'),
        },
        {
          target: '/produkte/tresor',
          image: 'assets/img/home/vault.svg',
          title: this.$t('texts.home.features.vault.title'),
          description: this.$t('texts.home.features.vault.description'),
        },
        {
          target: '/produkte/der-letzte-wunsch',
          image: 'assets/img/home/last-wish.svg',
          title: this.$t('texts.home.features.last_wish.title'),
          description: this.$t('texts.home.features.last_wish.description'),
        },
        {
          target: '/produkte/vorteilspartner',
          image: 'assets/img/home/benefit-partners.svg',
          title: this.$t('texts.home.features.benefit_partners.title'),
          description: this.$t('texts.home.features.benefit_partners.description'),
        }
      ],
      usagePoints: [
        {
          buttonText: this.$t('texts.home.usage_points.point1.button_text'),
          image: 'assets/img/home/fires.png',
          title: this.$t('texts.home.usage_points.point1.title'),
          description: this.$t('texts.home.usage_points.point1.description'),
        },
        {
          buttonText: this.$t('texts.home.usage_points.point2.button_text'),
          image: 'assets/img/home/floods.png',
          title: this.$t('texts.home.usage_points.point2.title'),
          description: this.$t('texts.home.usage_points.point2.description')
        },
        {
          buttonText: this.$t('texts.home.usage_points.point3.button_text'),
          image: 'assets/img/home/accidents.png',
          title: this.$t('texts.home.usage_points.point3.title'),
          description: this.$t('texts.home.usage_points.point3.description')
        },
      ],
      statistics: [
        {
          title: this.$t('texts.home.statistics.statistic_members'),
          number: 1500
        },
        {
          title: this.$t('texts.home.statistics.statistic_emergency_folders'),
          number: 1000
        },
        {
          title: this.$t('texts.home.statistics.statistic_emergency_cards'),
          number: 3000
        }
      ],
      showLogin: false,
      showRegister: false,
      pricingPlan: null
    }
  },
  mounted() {
    this.fillCompanySettingsArray();
  },
  methods: {
    scrollToFeatures() {
      const targetElement = document.getElementById('features');

      if (targetElement) {
        const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY - 100;

        window.scrollTo({
          top: targetPosition,
          behavior: 'smooth'
        });
      }
    },
    getCompanyValue() {
      return this.$route.query.company || '';
    },
    fillCompanySettingsArray() {
      const company = this.getCompanyValue();

      if (this.$store.getters.isLoggedIn) {
        return;
      }

      switch (company) {
        case 'adesso':
          this.companySettings.image = 'assets/img/home/adesso.png';
          this.companySettings.alt = 'adesso SE';
          this.setPrimaryColor('#006ec7');
          this.setSecondaryColor('#C8B600')
          break;
        case 'wohnbau':
          this.companySettings.image = 'assets/img/home/wohnbau.png';
          this.companySettings.title = this.$t('texts.home.title.wohnbau');
          this.companySettings.alt = 'Wohnbau eG';
          this.setPrimaryColor('#0089D0');
          this.setSecondaryColor('#3ABFB1')
          break;
      }
    },
    handleMainRegisterClick() {
      if (this.$store.getters.isLoggedIn) {
        this.$router.push({name: 'profileInfo'});
      } else {
        this.showRegister = true;
      }
    },
    openPricingRegisterModal(plan) {
      this.pricingPlan = plan
      this.showRegister = true
    },
    handleCloseRegisterModal() {
      this.pricingPlan = null;
      this.showRegister = false;
    },
    setPrimaryColor(color) {
      this.$vuetify.theme.themes.light.primary = color;
      this.$vuetify.theme.themes.dark.primary = color;
    },
    setSecondaryColor(color) {
      this.$vuetify.theme.themes.light.secondary = color;
      this.$vuetify.theme.themes.dark.secondary = color;
    }
  },
}
</script>

<style scoped>
#learn-more-icon {
  animation: pulsate 1.5s infinite;
}

#partner-logo-slider {
  --mask-left: linear-gradient(to left, #fff calc(100% - 6rem), transparent);
  --mask-right: linear-gradient(to right, #fff calc(100% - 6rem), transparent);
  -webkit-mask-image: var(--mask-left), var(--mask-right);
  -webkit-mask-size: 51% 100%;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: left, right;
}

@keyframes pulsate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>
