<template>
  <section>
    <v-app-bar
        id="app-bar"
        fixed
        app
        color="white"
        flat
        style="left: 0;"
        height="90"
        elevation="3"
    >
      <router-link :to="{name: 'home'}" class="ml-5 mt-1">
        <img src="/assets/img/logo.svg" alt="Geras24" :width="130"/>
      </router-link>
      <v-spacer/>
      <v-btn
          elevation="5"
          class="ml-2 hidden-sm-and-down"
          color="primary"
          @click="redirectToShop()"
      >
        <span>{{ $t('texts.footer.geras24_shop') }}</span>
      </v-btn>
      <v-btn
          v-if="!$store.getters.isLoggedIn"
          elevation="5"
          class="ml-2 hidden-xs-only"
          color="secondary"
          @click="$emit('open-register-modal')"
      >
        <span>{{ $t('create_account_for_free') }}</span>
      </v-btn>
      <v-btn
          v-if="!$store.getters.isLoggedIn"
          elevation="5"
          class="ml-2"
          color="secondary"
          @click="$emit('open-login-modal')"
      >
        <v-icon>mdi-account-outline</v-icon>
      </v-btn>
      <v-btn
          v-else
          class="ml-2 hidden-xs-only"
          min-width="0"
          text
          @click="logout()"
          :loading="loading"
          :disabled="loading"
      >
        <span>{{ $t('logout') }}</span>
        <v-icon small class="ml-2">mdi-logout</v-icon>
      </v-btn>
      <v-btn
          v-if="$store.getters.isLoggedIn && $store.getters.user.user ? $store.getters.user.user.role === 'Owner' : false"
          class="ml-2 pl-0 rounded-pill"
          min-width="0"
          text
          @click="dashboardIndex()"
          height="48"
      >
        <v-avatar size="48" class="elevation-5">
          <img
              :src="$store.getters.user.user.avatar || '/assets/img/avatar.png'"
          >
        </v-avatar>
        <div v-if="$vuetify.breakpoint.mdAndUp" class="ml-5 text-body-1">{{ $store.getters.user.user.firstname }} {{ $store.getters.user.user.name }}</div>
      </v-btn>
      <a v-else-if="$store.getters.isLoggedIn" href="/admin/dashboard">
        <v-btn
            class="ml-2 pl-0 rounded-pill"
            min-width="0"
            text
            height="48"
        >
          <v-avatar size="48" class="elevation-5">
            <img
                :src="$store.getters.user.user.avatar || '/assets/img/avatar.png'"
            >
          </v-avatar>
          <div v-if="$vuetify.breakpoint.mdAndUp" class="ml-5 text-body-1">{{ $store.getters.user.user.firstname }} {{ $store.getters.user.user.name }}</div>
        </v-btn>
      </a>
    </v-app-bar>
  </section>
</template>

<script>
// Components
import {VHover, VListItem} from "vuetify/lib";

export default {
  name: "DashboardCoreAppBar",

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({hover}) => {
              return h(
                  VListItem,
                  {
                    attrs: this.$attrs,
                    class: {
                      "black--text": !hover,
                      "white--text secondary elevation-12": hover
                    },
                    props: {
                      activeClass: "",
                      dark: hover,
                      link: true,
                      ...this.$attrs
                    }
                  },
                  this.$slots.default
              );
            }
          }
        });
      }
    }
  },
  props: ['payload', 'showLogin'],
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    dashboardIndex() {
      if (this.$store.getters.userIsLoggedInAsTrustedUser) {
        this.$router.push({path: 'dashboard/botschaften/medias/list/images'})
      } else if (this.$store.getters.adminIsLoggedInAsUser) {
        this.$router.push({path: 'dashboard/advisor/list'})
      } else {
        this.$router.push({name: 'profile'})
      }
    },
    logout() {
      this.loading = true

      this.$store.dispatch('logout')
          .then(() => {
            this.$store.commit('clear_user_imitation')
            this.$router.push({name: 'home'})
          })
          .catch(err => {
            this.loading = false

            this.$helpers.showError(err)
          })
    },
    redirectToShop() {
      window.open('https://shop.geras24.de', '_blank');
    }
  }
};
</script>
